<template>
  <div class="page">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>仓库名称：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="postFrom.warehouse_id" filterable clearable>
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>供应商名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-select="supplierSelect" @on-change="supplier" filterable v-model="supplierId" clearable>
            <Option v-for="(item, index) in supplierList" :value="item.supplier_id" :key="item.supplier_id + index">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>产品名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" :disabled="!queryFrom.supplier_id" v-model="queryFrom.product_id" clearable filterable>
            <Option v-for="item in productList" :value="item.product_id" :key="item.supplier_product_id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>规格型号/SKU：</span>
          <Select class="iviewIptWidth250 marginRight60" :disabled="!queryFrom.supplier_id" multiple filterable clearable v-model="queryFrom.code_id" :max-tag-count="queryFrom.code_id.length == 1 ? 1 : 0">
            <Option v-for="(item, index) in modelList" :value="item.code_id" :key="index" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="queryProductLookup">查询</span>
        </FormItem>
      </Form>
    </div>
    <div class="pageBtm">
      <div class="content">
        <Table :columns="listColumns" :data="meterList" border>
          <template slot-scope="{ row, index }" slot="unit_price">
            <div class="danjia">
              <span class="fl">¥ </span>
              <!--            <i-input class="fl" type="text" :value="row.unit_price" maxlength='8' placeholder="请输入" style="width: 100px;" @on-change="changeIpt($event, 'unit_price', index)"></i-input>-->
              <InputNumber :max="999999999.99" :min="0" :precision="2" v-model="row.unit_price" :active-change="false" style="width: 100px; margin-left: 5px;" @on-change="changeTotal($event, 'unit_price', index)"></InputNumber>
            </div>
          </template>
          <template slot-scope="{ row, index }" slot="number">
            <!--            <i-input placeholder="请输入" type="number" @on-change="changeIpt($event, 'quantity', index)"></i-input>-->
            <InputNumber :max="999999" :min="0" :precision="0" :value="row.quantity" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'quantity', index)"></InputNumber>
          </template>
        </Table>
        <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0">
          <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
        </div>
        <div class="summary" v-if="allNum > 0">
          <div>
            <span style="margin-right: 20px;"
              >总数量：<span class="color389">{{ allNum }}</span></span
            >
          </div>
        </div>
        <div class="tipsDiv clearfix">
          <div class="tipsFl">备注</div>
          <div class="tipsFr">
            <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.note"></i-input>
          </div>
        </div>
      </div>
      <div class="do clearfix">
        <span class="pageBtn finger btnSure marginLeft20 fr" @click="save(1)">提交</span>
        <span class="pageBtn finger btnSure fr marginLeft20" @click="save(0)">保存</span>
        <span class="pageBtn finger btnReset fr" @click="back">返回</span>
      </div>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" width="1200" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :columns="chooseColumns" :data="chooseList" border @on-selection-change="selectChange" height="500"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="changeSupplierStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否更换供应商名称？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="sureChangeSupplier">确定</span>
          <span class="pageBtn finger btnCancle" @click="cancleChangeSupplier">取消</span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'addPurchaseReturns',
  components: {
    TipsComponent,
  },
  computed: {
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.num * 1
      })
      return totle
    },
  },
  data() {
    return {
      deleteStatus: false,
      postStatus: false,
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          width: 150,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 70,
        },
        {
          title: '单价',
          align: 'center',
          width: 90,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '库存',
          key: 'inventory_quantity',
          align: 'center',
          width: 80,
        },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
        },
      ],
      changeSupplierStatus: false,
      list: [],
      supplierId: '',
      storeList: [], // 仓库
      supplierList: [],
      productList: [],
      modelList: [],
      meterList: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 200,
        },
        {
          title: '单价',
          slot: 'unit_price',
          align: 'center',
          width: 200,
        },
        {
          title: '数量',
          slot: 'number',
          align: 'center',
          width: 200,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      postFrom: {
        supplier_id: '',
        line_info: [],
        warehouse_id: '',
        note: '',
      },
      queryFrom: {
        supplier_id: '',
        product_id: '',
        code_id: '',
      },
      hadSelectList: [], // 弹窗已选数据
      clickIndex: Number, // 暂存点击删除数据下标
    }
  },
  mounted() {
    this.queryStoreList()
    this.querySupplierSelect()
  },
  methods: {
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      console.log(this.meterList, '删除')
    },
    // 点击删除按钮
    deleteRow(index) {
      this.clickIndex = index
      this.deleteStatus = true
    },
    back() {
      this.$router.go(-1)
    },
    // 保存
    save(num) {
      if (!this.queryFrom.supplier_id) {
        this.$Message.warning('请选择供应商名称')
        return
      }
      if (!this.postFrom.warehouse_id) {
        this.$Message.warning('请选择收货仓库')
        return
      }
      if (this.meterList.length == 0) {
        this.$Message.warning('请至少添加一个产品')
        return
      }
      for (let i = 0; i < this.meterList.length; i++) {
        this.meterList[i].product_name = this.meterList[i].name
        if (!this.meterList[i].quantity || !this.meterList[i].unit_price) {
          this.$Message.warning(`请完善第${i + 1}组产品的单价/数量`)
          return
        }
      }
      this.postFrom.supplier_id = this.queryFrom.supplier_id
      this.postFrom.line_info = JSON.parse(JSON.stringify(this.meterList))
      if (num) {
        this.postFrom.is_submit = '1'
        this.postStatus = true
        return
      }
      this.$http.post(this.$api.pruchaseReturnManage, this.postFrom, true).then(res => {
        this.$Message.success('保存成功')
        this.$router.go(-1)
      })
      sessionStorage.setItem('updataCache', '0')
    },
    // 最终确认提交
    surePost() {
      this.postStatus = false
      this.$http.post(this.$api.pruchaseReturnManage, this.postFrom, true).then(res => {
        this.$Message.success('提交成功')
        this.$router.go(-1)
      })
      sessionStorage.setItem('updataCache', '0')
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.chooseStatus = false
        return
      }
      this.chooseStatus = false
      if (this.meterList.length > 0) {
        let select = this.meterList.map(item => `${item.product_id}${item.code_id}`)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(`${i.product_id}${i.code_id}`)
        })
        let arr = [...this.meterList, ...this.hadSelectList]
        this.meterList = JSON.parse(JSON.stringify(arr))
      } else {
        this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
      }
      this.meterList.forEach((e, i) => {
        let num = this.meterList[i].quantity ? this.meterList[i].quantity : 0
        this.$set(this.meterList[i], 'quantity', num)
      })
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (!this.queryFrom.supplier_id) {
        this.$Message.warning('请先选择供应商名称再查询')
        return
      }
      // if (!this.queryFrom.product_id) {
      //   this.$Message.warning('请先选择产品再查询')
      //   return
      // }
      let query = {
        warehouse_id: this.postFrom.warehouse_id,
        supplier_id: this.queryFrom.supplier_id,
        product_id: this.queryFrom.product_id,
        code_id_str: this.queryFrom.code_id,
        purchase_return: '1',
      }
      this.$http.get(this.$api.productLookup, query, true).then(res => {
        if (res.data.length > 0) {
          this.hadSelectList = []
          this.chooseList = res.data
          this.chooseStatus = true
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 在已有产品的情况下 确认更换供应商
    sureChangeSupplier() {
      this.changeSupplierStatus = false
      this.queryFrom.product_id = ''
      this.queryFrom.supplier_id = this.supplierId
      this.productList = []
      this.meterList = []
      this.queryProductSelect(this.supplierId)
    },
    // 在已有产品的情况下 取消更改供应商
    cancleChangeSupplier() {
      this.supplierId = this.queryFrom.supplier_id
      this.changeSupplierStatus = false
    },
    supplier(e) {
      if (this.meterList.length > 0) {
        this.changeSupplierStatus = true
        return null
      } else {
        if (!e) {
          this.supplierId = null
          this.queryFrom.supplier_id = null
        }
      }
    },
    // 供应商名称修改 -- 考虑到有产品等其他情况下
    supplierSelect(e) {
      this.supplierId = e.value

      if (this.queryFrom.supplier_id && this.meterList.length > 0) {
        this.changeSupplierStatus = true
        return null
      } else {
        this.queryFrom.product_id = ''
        if (!e.value) return
        this.queryFrom.supplier_id = e.value
        this.productList = []
        this.queryProductSelect(e.value)
        this.queryProductCodeSelect()
      }
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.code_id = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下的产品下拉选择
    queryProductSelect(id) {
      this.$http.get(this.$api.productSelect, { supplier_id: id }, false).then(res => {
        this.productList = res.data
      })
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$api.productCodeSelect, { product_id: id, supplier_id: this.queryFrom.supplier_id }, true).then(res => {
        this.modelList = res.data
      })
    },
    // 供应商名称
    querySupplierSelect() {
      this.$http.fetch(this.$api.supplierSelect, true).then(res => {
        this.supplierList = res.data
      })
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    changeIpt(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
      } else {
        this.$set(this.meterList[index], name, e.target.value.trim() * 1)
      }
    },
    changeTotal(e, name, index) {
      this.$set(this.meterList[index], name, e * 1)
    },
  },
}
</script>

<style scoped lang="less">
.content::-webkit-scrollbar {
  width: 8px;
  padding-right: 2px;
}
.content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 2px solid #f2f2f2;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .pageBtm {
    padding: 0px 25px 25px 25px;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .content {
      flex: 1;
      overflow-y: auto;
    }
    .do {
      height: 36px;
      margin-top: 24px;
      overflow: hidden;
    }
  }
  .pageTop {
    float: right;
    padding: 20px 25px 0 25px;
  }
}
.marginRight60 {
  margin-right: 50px;
}
.title {
  margin-bottom: 24px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #525b6d;
  line-height: 25px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .tipsFr {
  flex: 1;
  display: flex;
  padding: 8px 15px 8px 23px;
  .ivu-input-wrapper {
    height: 100% !important;
  }
  .ivu-input {
    height: 100% !important;
  }
}
</style>
